import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@rails/ujs').start(); // eslint-disable-line
require('@rails/activestorage').start(); // eslint-disable-line
import 'bootstrap';
import ReactOnRails from 'react-on-rails';
import registerGlobalEventListeners from '../src/javascript/register_global_event_listeners';
import { initHubspotChat } from '../src/javascript/global/init_hubspot';
import { initFeatureFlagClient } from '../src/javascript/global/init_feature_flag_client';
import * as SharedComponents from '../src/javascript/registrations/shared_components';
import * as HomeComponents from '../src/javascript/registrations/home_components';

// eslint-disable-next-line
const images = require.context('../images', true);
// eslint-disable-next-line
const imagePath = (name) => images(name, true);

function registerReactComponents() {
  ReactOnRails.register({
    ...HomeComponents,
    ...SharedComponents,
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initFeatureFlagClient();
  registerReactComponents();
  registerGlobalEventListeners();
  initHubspotChat();
});
