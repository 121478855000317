import React, { useState, useMemo } from 'react';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import {
  Link,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  InputAdornment,
  Stack,
  Chip,
  Select,
  MenuItem,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { toggleOrgFavorite } from '../../apis/home/api';
import SnackMessage from '../../components/shared/snack_message';

const OrgRow = ({ org, depth, onToggleFavorite, onError, title }) => {
  const handleToggleFavorite = async (e) => {
    e.preventDefault();
    try {
      const response = await toggleOrgFavorite(org.id);
      onToggleFavorite(org.id, response.favorite);
    } catch (error) {
      onError(error || 'An error occurred while updating favorite status');
    }
  };

  return (
    <TableRow>
      <TableCell sx={{ pl: depth * 2.5, display: 'flex', alignItems: 'center', gap: 1 }}>
        {title !== 'Favorites' && (
          <Link
            component="button"
            onClick={handleToggleFavorite}
            sx={{
              color: org.favorite ? '#FFB800' : 'grey.400',
              '&:hover': {
                color: org.favorite ? '#E6A600' : 'grey.600',
              },
            }}
          >
            <FontAwesomeIcon icon={org.favorite ? faStarSolid : faStarLight} />
          </Link>
        )}
        {org.path ? (
          <Link href={org.path} className="link-default hover-underlined" variant="body1">
            {org.name}
          </Link>
        ) : (
          org.name
        )}
        {org.demo_data && (
          <Chip
            label="Demo"
            size="small"
            sx={{
              backgroundColor: 'grey.200',
              height: '20px',
              fontSize: '0.75rem',
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const OrgTable = ({ title, entities, renderOrgRows, fullHeight = false, showSearch = false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orgFilter, setOrgFilter] = useState('all');

  const filteredEntities = useMemo(() => {
    let filtered = entities;

    if (orgFilter === 'real') {
      filtered = filtered.filter((org) => !org.demo_data);
    } else if (orgFilter === 'demo') {
      filtered = filtered.filter((org) => org.demo_data);
    }

    if (searchTerm) {
      const searchTerms = searchTerm.toLowerCase().split(/\s+/);

      const filterOrgs = (orgs) => {
        return orgs.reduce((acc, org) => {
          const orgName = org.name.toLowerCase();
          const currentOrgMatches = searchTerms.every((term) => orgName.includes(term));

          if (org.children_orgs && org.children_orgs.length > 0) {
            const matchingChildren = filterOrgs(org.children_orgs);

            if (currentOrgMatches || matchingChildren.length > 0) {
              acc.push({
                ...org,
                children_orgs: matchingChildren,
              });
            }
          } else if (currentOrgMatches) {
            acc.push(org);
          }

          return acc;
        }, []);
      };

      filtered = filterOrgs(filtered);
    }

    return filtered;
  }, [entities, searchTerm, orgFilter]);

  return (
    <Paper elevation={2} sx={{ p: 3, borderRadius: 2, height: fullHeight ? '100%' : 'auto', minHeight: '160px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, gap: 2 }}>
        <Typography variant="h6" className={'blue-header'} sx={{ flexShrink: 0, display: 'flex', alignItems: 'center', gap: 1 }}>
          {title === 'Favorites' && <FontAwesomeIcon icon={faStarSolid} style={{ color: '#FFB800', fontSize: '1.2em' }} />}
          {title}
        </Typography>
        {showSearch && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, maxWidth: '400px' }}>
            <TextField
              size="small"
              placeholder="Search..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flexGrow: 1,
                '& .MuiOutlinedInput-root': {
                  height: '32px',
                  fontSize: '0.875rem',
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.15)',
                  },
                },
              }}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              size="small"
              value={orgFilter}
              onChange={(e) => setOrgFilter(e.target.value)}
              sx={{
                minWidth: 120,
                height: '32px',
                fontSize: '0.875rem',
                '& .MuiSelect-select': {
                  py: '4px',
                },
              }}
            >
              <MenuItem value="all">All Orgs</MenuItem>
              <MenuItem value="real">Real Orgs</MenuItem>
              <MenuItem value="demo">Demo Orgs</MenuItem>
            </Select>
          </Box>
        )}
      </Box>
      <Table size="small">
        <TableBody>{filteredEntities.map((o) => renderOrgRows(o, title))}</TableBody>
      </Table>
    </Paper>
  );
};

const Container = ({ entities }) => {
  const [orgs, setOrgs] = useState(entities);
  const [snackMessage, setSnackMessage] = useState({ open: false, message: '', severity: 'error' });

  const handleToggleFavorite = (orgId, favoriteStatus) => {
    setOrgs((prevOrgs) => {
      const updateOrg = (org) => {
        if (org.id === orgId) {
          return { ...org, favorite: favoriteStatus };
        }

        if (org.children_orgs) {
          return {
            ...org,
            children_orgs: org.children_orgs.map((child) => updateOrg(child)),
          };
        }

        return org;
      };

      return prevOrgs.map((org) => updateOrg(org));
    });
  };

  const handleCloseSnack = () => {
    setSnackMessage({ ...snackMessage, open: false });
  };

  const handleError = (errorMessage) => {
    setSnackMessage({
      open: true,
      message: errorMessage || 'An error occurred while updating favorite status',
      severity: 'error',
    });
  };

  const renderOrgRows = (org, title, depth = 0) => {
    const rows = [];
    rows.push(<OrgRow key={org.id} org={org} depth={depth} onToggleFavorite={handleToggleFavorite} onError={handleError} title={title} />);

    if (org.children_orgs) {
      org.children_orgs.forEach((childOrg) => {
        rows.push(...renderOrgRows(childOrg, title, depth + 1));
      });
    }

    return rows;
  };

  const getFavoriteOrgsWithStructure = (orgs) => {
    return orgs
      .map((org) => {
        const favoriteChildren = org.children_orgs ? getFavoriteOrgsWithStructure(org.children_orgs).filter(Boolean) : [];

        if (org.favorite) {
          return {
            ...org,
            children_orgs: favoriteChildren,
          };
        }

        if (favoriteChildren.length > 0) {
          return favoriteChildren;
        }

        return null;
      })
      .flat()
      .filter(Boolean);
  };

  const favoriteOrgs = getFavoriteOrgsWithStructure(orgs);
  const hasFavorites = favoriteOrgs.some((org) => org.favorite || org.children_orgs.some((child) => child.favorite));

  const countTotalOrgs = (orgs) => {
    return orgs.reduce((count, org) => {
      return count + 1 + (org.children_orgs ? countTotalOrgs(org.children_orgs) : 0);
    }, 0);
  };

  const totalOrgs = countTotalOrgs(entities);
  const showSearch = totalOrgs >= 20;

  return (
    <Box className="homepage" sx={{ maxWidth: '1024px', mx: 'auto' }}>
      <Stack spacing={3}>
        {hasFavorites && (
          <OrgTable
            title="Favorites"
            entities={favoriteOrgs}
            renderOrgRows={renderOrgRows}
            fullHeight={false}
            showSearch={favoriteOrgs.length >= 20}
          />
        )}

        <OrgTable title="All Organizations" entities={orgs} renderOrgRows={renderOrgRows} fullHeight={true} showSearch={showSearch} />
      </Stack>
      <SnackMessage isOpen={snackMessage.open} handleClose={handleCloseSnack} message={snackMessage.message} severity={snackMessage.severity} />
    </Box>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props} />
      </ThemeProvider>
    </Layout>
  );
};
