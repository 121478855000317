import { getHeaders } from '../headers';

export const toggleOrgFavorite = async (orgId) => {
  const response = await fetch(`/orgs/${orgId}/toggle_favorite`, {
    method: 'POST',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};
