import React, { useState } from 'react';
import Layout from '../layouts/application';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure, useHits, useInstantSearch } from 'react-instantsearch';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import complete from 'images/complete.png';
import complete2x from 'images/complete@2x.png';
import dueSoon from 'images/due_soon.png';
import dueSoon2x from 'images/due_soon@2x.png';
import overdue from 'images/overdueRed.png';
import overdue2x from 'images/overdueRed@2x.png';

const headCells = [
  { id: 'type', numeric: false, label: 'Type', sortable: true },
  { id: 'numbering', numeric: false, label: 'Num', sortable: true },
  { id: 'description', numeric: false, label: 'Description', sortable: false },
  { id: 'task_status', numeric: false, label: 'Status', sortable: true },
  { id: 'due_date_timestamp', numeric: false, label: 'Due', sortable: true },
];

const statusImages = {
  complete: { src: complete, srcSet: `${complete2x} 2x` },
  overdue: { src: overdue, srcSet: `${overdue2x} 2x` },
  due_soon: { src: dueSoon, srcSet: `${dueSoon2x} 2x` },
};

const renderStatus = (hit) => {
  if (hit.task_status == null) return;
  const image = statusImages[hit.task_status];
  return image && <img className="d-block" src={image.src} srcSet={image.srcSet} height="20px"></img>;
};

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={'left'} padding={'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Hit = ({ hit }) => {
  return (
    <TableRow>
      <TableCell align="left">{hit.type}</TableCell>
      <TableCell align="left">{hit.numbering}</TableCell>
      <TableCell align="left">
        <a className="link-default" href={hit.search_result_path}>
          {hit.name || hit.description || ' '}
        </a>
      </TableCell>
      <TableCell align="left">{renderStatus(hit)}</TableCell>
      <TableCell>{hit.due_date_timestamp && new Date(hit.due_date_timestamp * 1000).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
    </TableRow>
  );
};

const createFilterText = (props, indexUiState) => {
  let filterText = 'You have no upcoming tasks';
  if (props.dateRangeEnd) {
    filterText += ` due in the next ${props.dateRangeEnd} days`;
  }
  if (indexUiState.query) {
    filterText += ` matching the search "${indexUiState.query}"`;
  }
  filterText += '.';
  return filterText;
};

const CustomHits = (props) => {
  let { hits, results, sendEvent } = useHits(props);
  const { indexUiState } = useInstantSearch();
  const [orderBy, setOrderBy] = useState('due_date_timestamp');
  const [order, setOrder] = useState('asc');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const newIndexName = `PlanElements-${property}_${isAsc ? 'desc' : 'asc'}`;
    props.setIndexName(newIndexName);
  };

  if (hits.length > 0) {
    return (
      <TableContainer>
        <Table size={'small'}>
          <SortableTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {hits.map((hit) => (
              <Hit hit={hit} key={hit.objectID} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    const filterText = createFilterText(props, indexUiState);
    return <div className="text-center">{filterText}</div>;
  }
};

const xDaysFromNow = (x) => {
  const d = new Date();
  d.setDate(d.getDate() + x);
  return Math.floor(d.getTime() / 1000);
};

const Container = (props) => {
  const searchClient = algoliasearch(props.algoliaAppId, props.algoliaSearchKey);
  const [indexName, setIndexName] = useState('PlanElements-due_date_timestamp_asc');
  const [dateRangeEnd, setDateRangeEnd] = useState(365);
  const dateFilter = dateRangeEnd ? ` AND due_date_timestamp<=${xDaysFromNow(dateRangeEnd)}` : '';
  const projectPlanFilter = props.projectPlanId ? ` AND project_plan_id=${props.projectPlanId}` : '';
  const filters = `type:Deliverable OR type:Result AND tasked_user_ids:${props.currentUserId}${dateFilter}${projectPlanFilter} AND NOT task_status:complete`;

  return (
    <Paper className="my-4 px-4 py-4" elevation={3}>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <div className="d-flex justify-content-between">
          <h4>My Upcoming Tasks</h4>
          <div className="col-6 mb-4">
            <Configure filters={filters} hitsPerPage={1000} />
            <div className="d-flex flex-nowrap align-items-center gap-2 me-3">
              <SearchBox placeholder="Search My Tasks" className="col-8" />
              <Select sx={{ minWidth: 120 }} size="small" className="col-4 ms-3 no-legend custom-select" value={dateRangeEnd} label="When">
                <MenuItem onClick={() => setDateRangeEnd(false)} value={false}>
                  All
                </MenuItem>
                <MenuItem onClick={() => setDateRangeEnd(7)} value={7}>
                  Next 7 Days
                </MenuItem>
                <MenuItem onClick={() => setDateRangeEnd(30)} value={30}>
                  Next 30 Days
                </MenuItem>
                <MenuItem onClick={() => setDateRangeEnd(90)} value={90}>
                  Next 90 Days
                </MenuItem>
                <MenuItem onClick={() => setDateRangeEnd(365)} value={365}>
                  1 Year
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <CustomHits dateRangeEnd={dateRangeEnd} setIndexName={setIndexName} />
      </InstantSearch>
    </Paper>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
