import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Divider, Stack, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

const GlobalAccount = ({
  name,
  email,
  hasAdminAccess,
  hasRequiredAuthenticator,
  isSuperAdmin,
  adminUrl,
  helpGuideUrl,
  logoutUrl,
  dataModelsManagerUrl,
  orgId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyle = {
    color: 'inherit !important',
    textDecoration: 'none',
    minWidth: '260px',
  };

  const readOnlyMenuItemStyle = {
    textDecoration: 'none',
    minWidth: '260px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  };

  return (
    <div>
      <IconButton size="large" aria-label="account settings" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem sx={{ ...readOnlyMenuItemStyle, marginBottom: 1.5 }} disableRipple>
          <Stack spacing={0.3} sx={{ width: '100%' }}>
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {name}
            </Typography>
            <Typography component="small" sx={{ color: (theme) => theme.palette.grey[600] }}>
              {email}
            </Typography>
          </Stack>
        </MenuItem>
        {!hasRequiredAuthenticator && (
          <MenuItem component="a" href="/settings" sx={menuItemStyle}>
            Account
          </MenuItem>
        )}
        {orgId && (
          <MenuItem component="a" href={`/orgs/${orgId}/notifications_settings`} sx={menuItemStyle}>
            Notification Settings
          </MenuItem>
        )}
        {hasAdminAccess && (
          <MenuItem component="a" href={adminUrl} sx={menuItemStyle}>
            {isSuperAdmin ? 'Global Admin' : 'User Admin'}
          </MenuItem>
        )}
        {isSuperAdmin && (
          <MenuItem component="a" href={dataModelsManagerUrl} sx={menuItemStyle}>
            Data Models Manager
          </MenuItem>
        )}
        {helpGuideUrl && (
          <MenuItem component="a" href={helpGuideUrl} sx={menuItemStyle}>
            User Guide
          </MenuItem>
        )}
        <Divider />
        <MenuItem component="a" href={logoutUrl} data-method="delete" rel="nofollow" sx={menuItemStyle}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default GlobalAccount;
